import { GET_SERVICE_DISRUPTIONS_BY_TYPES } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { ServiceDisruptionHookReturn } from "OnlineTypes";
import { ServiceDisruption, TypeOfServiceDisruption } from "Types";

type ServiceDisruptionHookProps = (serviceDisruptionTypes: TypeOfServiceDisruption[]) => ServiceDisruptionHookReturn;

export const useServiceDisruptions: ServiceDisruptionHookProps = serviceDisruptionTypes => {
    const { data: serviceDisruptions, refetch: refetchServiceDisruptions } = useMothershipQuery<{
        getDisruptedServicesByTypes: ServiceDisruption[];
    }>(GET_SERVICE_DISRUPTIONS_BY_TYPES, {
        fetchPolicy: "network-only",
        variables: { serviceDisruptionTypes: serviceDisruptionTypes }
    });

    const { getDisruptedServicesByTypes = [] } = serviceDisruptions || {};
    const distruptionTypes = getDisruptedServicesByTypes.map(value => value.typeOfServiceDisruption);

    return {
        isSwishServiceDisrupted: distruptionTypes.includes(TypeOfServiceDisruption.SWISH),
        isCardServiceDisrupted: distruptionTypes.includes(TypeOfServiceDisruption.CARD),
        isKlarnaServiceDisrupted: distruptionTypes.includes(TypeOfServiceDisruption.KLARNA),
        refetchServiceDisruption: refetchServiceDisruptions
    };
};
